import React from "react"
import styled from "styled-components"

import headerImg from "../images/header.jpg"
import smallHeaderImg from "../images/header-small.jpg"

import Counter from "./Counter"
import Title from "./Title"

const Names = styled.h1`
  font-size: 6rem;
  margin-bottom: 1rem;
`

const Subtitle = styled.h2`
  font-family: inherit;
  font-size: 1.6rem;
  margin-bottom: 2rem;
`

const FunnyTitle = styled(Title)``

export default () => (
  <FunnyTitle image={headerImg} smallImage={smallHeaderImg}>
    <Names>Tom & Elina</Names>
    <Subtitle>are getting married 19th of August 2020</Subtitle>
    <Counter eventDateTime={new Date("2020-08-19T16:00:00.000+02:00")} />
  </FunnyTitle>
)
