import React from "react"
import styled from "styled-components"

import { ContentWrapper, Content } from "./Content"

const Button = styled.a`
  padding: 0.5rem;
  border-radius: 0.25rem;
  background-color: green;
  color: white;
  text-decoration: none;
`

export default () => (
  <ContentWrapper>
    <Content>
      <p>
        So we are finally getting married! Woo! And if you are here then I'm
        guessing you are invited. There is going to be good food (I hope), great
        music (we've got a 3 day long spotify playlist) and bad dancing (you've
        met us already).
      </p>
      <p>
        We'd love to know if you are coming and if you are bringing anyone. So
        hit the button and let us know.
      </p>
      <Button href="mailto:me+wedding@tomandrews.co.uk?subject=RSVP&body=Yo nerds, I'm coming with my bf/gf/wife/husband/offspring/cat and I'm driving/busing.">
        RSVP
      </Button>
    </Content>
  </ContentWrapper>
)
