import React, { useState } from "react"
import styled from "styled-components"
import useInterval from "@use-it/interval"

const Wrapper = styled.div`
  font-size: 1.2rem;
  font-family: "Ubuntu", sans-serif;
`

const Circle = styled.span`
  display: inline-block;
  text-align: center;

  border: 1px solid black;
  color: black;
  background-color: white;
  width: 3rem;
  height: 3rem;
  padding: 0.75rem 0.25rem 0.25rem 0.25rem;

  border-radius: 100%;

  font-size: 1.2rem;
`

const TimingWrapper = styled.div`
  display: inline-block;
  margin: 0 0.25rem;
`

const TimingTitle = styled.span`
  display: block;
  font-size: 0.7rem;
  margin-top: 0.5rem;
`

const Timing = ({ title, counter }) => (
  <TimingWrapper>
    <Circle>{counter}</Circle>
    <TimingTitle>{title}</TimingTitle>
  </TimingWrapper>
)

const Counter = ({ eventDateTime }) => {
  const [timings, setTimings] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  })

  useInterval(() => {
    const difference = eventDateTime - Date.now()
    const days = Math.floor(difference / 1000 / 60 / 60 / 24)
    const hours = Math.floor((difference / 1000 / 60 / 60) % 24)
    const minutes = Math.floor((difference / 1000 / 60) % 60)
    const seconds = Math.floor((difference / 1000) % 60)
    setTimings({
      days,
      hours,
      minutes,
      seconds,
    })
  }, 1000)

  return (
    <Wrapper>
      <Timing title="Days" counter={timings.days} />
      <Timing title="Hours" counter={timings.hours} />
      <Timing title="Minutes" counter={timings.minutes} />
      <Timing title="Seconds" counter={timings.seconds} />
    </Wrapper>
  )
}

export default Counter
