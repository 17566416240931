import React from "react"
import Helmet from "react-helmet"

import Layout from "../components/Layout"
import Header from "../components/Header"
import Intro from "../components/Intro"
import Venue from "../components/Venue"
import Schedule from "../components/Schedule"
import Footer from "../components/Footer"

const IndexPage = () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Tom And Elina</title>
    </Helmet>
    <Layout>
      <Header />
      <Intro />
      <Venue />
      <Schedule />
      <Footer />
    </Layout>
  </>
)

export default IndexPage
