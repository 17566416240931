import React from "react"

import Title from "./Title"
import { ContentWrapper, Content } from "./Content"
import venuImg from "../images/venue.jpg"

export default () => (
  <>
    <Title image={venuImg}>
      <h2>The Venue</h2>
    </Title>
    <ContentWrapper>
      <Content>
        <p>
          So we are going to hang out for a couple of days at{" "}
          <a href="https://www.niedras.lv/" title="Niedras venue">
            Niedras
          </a>{" "}
          which is about an hour out of Riga. We will be running a bus from Riga
          so no need to rent a car for 2 days. We will update y'all on the exact
          time and location when we know it. However if you want to drive
          yourself there is plenty of parking. Also as we are dragging you out
          to the middle of nowhere there is going to be accomodation provided so
          no need to stress about hotels, tents etc - we've got it covered.
        </p>
        <p>
          Remember to bring your party shoes and swimming gear! Weather
          permitting we will be outside so bring a rain coat just in case any of
          you brought the rain with you.
        </p>
        <p>
          Hey if you are need directions then you can check the place out on the
          map to the right.
        </p>
      </Content>
      <Content>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d17555.515194230116!2d22.96880478854658!3d56.63231087504022!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5a62796c59dc14b!2sKempings%20Niedras%20%2C%20SIA!5e0!3m2!1sen!2suk!4v1579625383750!5m2!1sen!2suk"
          width="100%"
          height="100%"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen=""
          title="Map to venue"
        ></iframe>
      </Content>
    </ContentWrapper>
  </>
)
