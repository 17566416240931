import styled from "styled-components"

const Parallax = styled.div`
  background-image: url(${props => props.image});

  @media (max-width: 1025px) {
    ${props =>
      props.smallImage && `background-image: url(${props.smallImage})`};
  }

  height: 100%;

  background-attachment: fixed;
  @supports (-webkit-touch-callout: none) {
    /* CSS for iOS devices */
    background-attachment: initial;
  }
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

export default Parallax
