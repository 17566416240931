import React from "react"
import styled from "styled-components"

import Parallax from "./Parallax"

const Content = styled.div`
  background: rgba(16, 16, 16, 0.3);
  text-align: center;
  color: white;

  font-weight: normal;
  font-size: 5rem;

  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`

const Wrapper = styled.div``

export default ({ image, smallImage, children }) => (
  <Parallax image={image} smallImage={smallImage}>
    <Content>
      <Wrapper>{children}</Wrapper>
    </Content>
  </Parallax>
)
