import styled from "styled-components"

const SMALLEST_DEVICE_WIDTH = 320

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1000px;
  margin: auto;
  padding: 2rem 1rem 1rem;
`

export const Content = styled.div`
  flex-basis: 100%;
  flex: 1;
  min-width: ${SMALLEST_DEVICE_WIDTH}px;
  max-width: 1000px;
  text-align: justify;
  line-height: 1.9;
  padding: 0 1rem 1rem;
  font-size: 1.2rem;
`
