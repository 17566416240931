import React from "react"
import styled from "styled-components"

import Title from "./Title"

const Subtitle = styled.p`
  font-size: 1rem;
`

export default () => (
  <footer>
    <Title>
      <h2>We hope to see you there!</h2>
      <Subtitle>
        Remember if you have any questions then don't hesitate to ask
      </Subtitle>
    </Title>
  </footer>
)
