import React from "react"
import styled from "styled-components"

import Title from "./Title"
import { ContentWrapper, Content } from "./Content"
import scheduleImg from "../images/schedule.jpg"
import scheduleSVG from "../images/schedule.svg"

const Wrapper = styled.div`
  margin: auto;
  max-width: 780px;
`

export default () => (
  <>
    <Title image={scheduleImg}>
      <h2>The Schedule</h2>
    </Title>
    <ContentWrapper>
      <Content>
        <Wrapper>
          <p>
            So this is a <i>rough</i> plan for the day. Not including the bus
            from Riga centre.
          </p>
          <img src={scheduleSVG} alt="Schedule of events" />
        </Wrapper>
      </Content>
    </ContentWrapper>
  </>
)
